import React, { useCallback, useEffect, useState, useRef } from 'react'
import { useSearchParams, Link } from 'react-router-dom'
import CustomStore from 'devextreme/data/custom_store'
import Button from 'devextreme-react/button'
import notify from '../../utils/notify'
import useBeforeUnload from '../../hooks/useBeforeUnload'
import DataGrid, {
	Column,
	Toolbar,
	Item,
	Grouping,
	GroupPanel,
	ColumnChooser,
	Selection,
	Scrolling,
	Editing,
	Paging,
	Lookup,
	FilterRow,
	HeaderFilter,
	MasterDetail,
	Export,
	Summary,
	GroupItem,
	FilterPanel,
	RequiredRule,
	KeyboardNavigation,
} from 'devextreme-react/data-grid'
import {
	loadData,
	insertData,
	editData,
	deleteData,
	fixDataType,
	handleOptionChange,
	handleExporting,
	isNotEmpty,
	renderUrl,
	filterStore,
	lookupStore,
} from '../../utils/model-data'
import {
	Detail,
	Map,
	Row,
	useSharePoint,
	MultipleSelect,
	cellMultipleSelect,
	confirmDialog,
	AuditTrail,
	UploadField,
} from '../../components'
import { Popup } from 'devextreme-react/popup'
import { LinkPermit } from '../../popups'
import { saveAs } from 'file-saver'
import axios from 'axios'
interface DynamicLookup {
	[key: string]: Array<{ value: string; text: string }>
}

interface SortCriteria {
	field: string
	order: 'asc' | 'desc'
}

export default function Model() {
	const [searchParams] = useSearchParams()
	const folder = searchParams.get('folder')
	const model = searchParams.get('model')
	let filter = atob(searchParams.get('filter') ?? '')
	filter = filter ? JSON.parse(filter) : ''

	const [key, setKey] = useState<any>(null)
	const [fields, setFields] = useState([])
	const [detail, setDetail] = useState<any>([])
	const [buttons, setButtons] = useState([])
	const [lookups, setLookups] = useState([])
	const [dynamicLookups, setDynamicLookups] = useState<DynamicLookup>({})
	const [rowDynamicLookup, setRowDynamicLookup] = useState<DynamicLookup>({})
	const [multipleLookups, setMultipleLookups] = useState<any>([])
	const [sizes, setSizes] = useState([])
	const [locks, setLocks] = useState<boolean | []>([])
	const [locksEdit, setLocksEdit] = useState<[]>([])
	const [dynamicLocks, setDynamicLocks] = useState<[]>([])
	const [sort, setSort] = useState<SortCriteria>()
	const [inserting, setInserting] = useState<boolean>(false)
	const [updating, setUpdating] = useState<boolean>(false)
	const [bulkUpdating, setBulkUpdating] = useState<boolean>(false)
	const [duplicating, setDuplicating] = useState<boolean>(false)
	const [buttonPopupShow, setButtonPopupShow] = useState(false)
	const [buttonArgs, setButtonArgs] = useState<[]>([])
	const [deleting, setDeleting] = useState<boolean>(false)
	const [grouping, setGrouping] = useState(0)
	const [urls, setUrls] = useState([])
	const [map, setMap] = useState<any>([])
	const [showMap, setShowMap] = useState(false)
	const [showRow, setShowRow] = useState(false)
	const [dataSource, setDataSource] = useState<any>()
	const [dataFilter, setDataFilter] = useState<string | null>(null)
	const [totalCount, setTotalCount] = useState<number | null>(null)
	const [modelText, setModelText] = useState('')
	const [height, setHeight] = useState(0)
	const [selectedRow, setSelectedRow] = useState<[]>([])
	const [rowColors, setRowColors] = useState<[]>([])
	const [cellColors, setCellColors] = useState<[]>([])
	const [mapColorFields, setMapColorFields] = useState([])
	const [percentagesFields, setPercentagesFields] = useState([])
	const [fileUploadFields, setFileUploadFields] = useState([])
	const [exportWithPhotos, setExportWithPhotos] = useState(false)
	const [valueColors, setValueColors] = useState<any>([])
	const [detailFieldsSettings, setDetailFieldsSettings] = useState<any[]>([])
	const [detailFieldsSizes, setDetailFieldsSizes] = useState<any>({})
	const [detailFields, setDetailFields] = useState<any>({})
	const [clipboard, setClipboard] = useState<any>(null)
	const [editorMode, setEditorMode] = useState<boolean>(false)
	const [isNewRow, SetIsNewRow] = useState(false)
	const [hovers, setHovers] = useState<any>([])
	const [showAuditTrail, setShowAuditTrail] = useState(false)
	const [auditData, setAuditData] = useState<any>([])
	const [auditField, setAuditField] = useState<string>('')

	const gridRef = useRef<DataGrid<any, any> | null>(null)
	const { sharePointLogged } = useSharePoint()

	useBeforeUnload(editorMode)

	const fieldsDataTypes: Array<string> = []

	const handleMapPopupClose = () => {
		setShowMap(false)
	}

	const handleClick = (data: any, model: string, filter: any) => {
		if (filter && filter.length > 0) {
			const clonedFilter = filter.slice()
			const value = data[0][clonedFilter[2]]
			clonedFilter[2] = value
			const base64 = btoa(clonedFilter)
			const url = `/m?folder=${folder}&model=${model}&filter=${encodeURIComponent(base64)}`
			window.open(url, '_blank')
		} else {
			const url = `/m?folder=${folder}&model=${model}`
			window.open(url, '_blank')
		}
	}

	const onFocusedRowChanged = useCallback(
		(e: any) => {
			if (key && e.row && e.row.data) {
				setSelectedRow(e.row.data)
			}
		},
		[key]
	)

	useEffect(() => {
		const handleResize = () => {
			const viewportHeight = window.innerHeight
			setHeight(viewportHeight - 37)
		}

		handleResize()
		window.addEventListener('resize', handleResize)
		return () => window.removeEventListener('resize', handleResize)
	}, [])

	const loadDynamicLookup = useCallback(
		(dataField: string) => {
			let url = `${API_FIBER_URL}/view/lookup?folder=${folder}&model=${model}&field=${dataField}`
			if (dataFilter) {
				url = `${url}&filter=${dataFilter}`
			}
			axios
				.get(url)
				.then(response => response.data)
				.then(data => {
					setDynamicLookups(dynamicLookups => ({
						...dynamicLookups,
						[dataField]: data.lookup,
					}))
				})
		},
		[folder, model, dataFilter]
	)

	useEffect(() => {
		if (model) {
			axios
				.get(`${API_FIBER_URL}/view/fields?folder=${folder}&model=${model}`)
				.then(response => response.data.data)
				.then(data => {
					setKey(data.key)
					setFields(data.fields)
					setDetail(data.detail)
					setButtons(data.buttons)
					setLookups(data.lookups)
					setSizes(data.sizes)
					setLocks(data.locks)
					setLocksEdit(data.locksEdit)
					setDynamicLocks(data.dynamicLocks)
					setSort(data.sort)
					setInserting(data.inserting)
					setUpdating(data.updating)
					setDeleting(data.deleting)
					setDuplicating(data.duplicating)
					setBulkUpdating(data.bulkUpdating)
					setModelText(data.text || model)
					setHovers(data.hovers)
					setUrls(data.urls)
					setMap(data.map)
					setRowColors(data.rowColors)
					setCellColors(data.cellColors)
					setMapColorFields(data.mapColorFields)
					setPercentagesFields(data.percentagesFields)
					setFileUploadFields(data.fileUploadFields)
					setExportWithPhotos(data.exportWithPhotos)
					setMultipleLookups(data.multipleLookups)
					Object.keys(data.dynamicLookups).forEach(key => {
						loadDynamicLookup(key)
					})
				})
		}
		// eslint-disable-next-line
	}, [folder, model])

	useEffect(() => {
		if (detail.model) {
			axios
				.get(`${API_FIBER_URL}/view-detail/fields?folder=${folder}&model=${detail.model}&master=${model}`)
				.then(response => response.data.data)
				.then(data => {
					setDetailFieldsSettings(data)
					setDetailFieldsSizes(data.sizes)
					setDetailFields(data.fields)
				})
		}
	}, [folder, model, detail])

	useEffect(() => {
		const keysToLoad = Object.keys(dynamicLookups)
		if (keysToLoad.length > 0) {
			keysToLoad.forEach(key => {
				loadDynamicLookup(key)
			})
		}
		// eslint-disable-next-line
	}, [dataFilter])

	useEffect(() => {
		document.title = `Fiber :: ${modelText}`
	}, [modelText])

	useEffect(() => {
		if (model) {
			const params: any = { folder, model, skip: 0, take: 1, requireTotalCount: 1 }
			if (dataFilter) {
				params.filter = dataFilter
			}
			axios
				.post(`${API_FIBER_URL}/view/data`, params)
				.then(response => response.data)
				.then(data => {
					setTotalCount(data.totalCount)
				})
		}
	}, [folder, model, dataFilter])

	useEffect(() => {
		if (key) {
			const ds = new CustomStore({
				key: key,
				load: (loadOptions: any) => {
					if (grouping === 0) {
						const filter = isNotEmpty(loadOptions['filter']) ? JSON.stringify(loadOptions['filter']) : null
						setDataFilter(filter)
					}
					return loadData('view', loadOptions, { folder, model })
				},
				insert: values => {
					return insertData('view', { values, folder, model })
				},
				update: (key, values) => {
					return editData('view', { key, values, folder, model })
				},
				remove: key => {
					return deleteData('view', { key, folder, model })
				},
			})
			setDataSource(ds as any)
		}
	}, [folder, model, key, grouping])

	function filterConvertData(field: string, data: string) {
		switch (fieldsDataTypes[field as any]) {
			case 'date':
				data = data.substring(0, 10)
				break
		}
		return data
	}

	function DetailSection(props: any) {
		const data = props.data.data
		const { filter, composeFilter } = detail
		let filterClone: any = []
		if (composeFilter) {
			filterClone = JSON.parse(JSON.stringify(composeFilter))
			Object.entries(filterClone).forEach(([index, filterItem]) => {
				if (Array.isArray(filterItem)) {
					filterClone[index as any][2] = filterConvertData(filterItem[2], data[filterItem[2]])
				}
			})
		} else {
			filterClone = JSON.parse(JSON.stringify(filter))
			filterClone[2] = filterConvertData(filterClone[2], data[filterClone[2]])
		}

		return (
			<Detail
				folder={folder}
				model={detail?.model}
				master={model}
				settings={detailFieldsSettings}
				sizes={detailFieldsSizes}
				fields={detailFields}
				refreshSettings={(action: string, data: any) => {
					switch (action) {
						case 'reorder':
						case 'hide':
							setDetailFields(data.fields)
							setDetailFieldsSizes(data.sizes)
							break
						case 'resize':
							setDetailFieldsSizes(data.sizes)
							break
					}
				}}
				filter={filterClone}
			/>
		)
	}

	function UploadSection(props: any) {
		const name = props.data.column.dataField
		const settings = fileUploadFields[name]
		const value = props.data.value
		const setValue = props.data.setValue
		return (
			<UploadField
				folder={folder}
				model={model}
				name={name}
				value={value}
				setValue={setValue}
				settings={settings}
			/>
		)
	}

	const allowEditing = useCallback(
		(field: string, allowEditing: any): boolean => {
			if (allowEditing) {
				if (isNewRow) {
					return allowEditing
				}
				if (locksEdit.length === 0) {
					return allowEditing
				}
			}
			if (field === key) {
				return false
			}
			if (locks === true) {
				if (isNewRow) {
					return false
				}
				if (locksEdit.length === 0) {
					return false
				}
			}
			const locksAr = !isNewRow && locksEdit.length > 0 ? locksEdit : (locks as [])
			if (locksAr.indexOf(field as never) > -1) {
				return false
			}
			if (editorMode && dynamicLookups[field]) {
				return false
			}
			return true
		},
		[isNewRow, locks, locksEdit, editorMode, dynamicLookups, key]
	)

	const rowPrepared = (e: any) => {
		if (e.rowType === 'data') {
			const { data: rowData, rowElement } = e
			rowColors.forEach(({ field, fieldValue, field2, fieldValue2, operator, color }) => {
				let shouldApplyColor = false
				switch (operator) {
					case 'AND':
						shouldApplyColor = rowData[field] === fieldValue && rowData[field2] === fieldValue2
						break
					case 'OR':
						shouldApplyColor = rowData[field] === fieldValue || rowData[field2] === fieldValue2
						break
					default:
						shouldApplyColor = rowData[field] === fieldValue
				}
				if (shouldApplyColor) {
					rowElement.style.backgroundColor = color
				}
			})
		}
	}

	const cellPrepared = (e: any) => {
		if (e.rowType === 'data') {
			const rowData = e.data
			cellColors.forEach((item: any) => {
				if (
					e.column.dataField === item.field &&
					rowData[item.field] &&
					rowData[item.field] === item.fieldValue
				) {
					e.cellElement.style.cssText = `background-color: ${item.color}`
				}
			})
		} else if (e.rowType === 'header') {
			const tooltipText = hovers[e.column.dataField] || ''
			e.cellElement.setAttribute('title', tooltipText)
		}
	}

	const onEditingStart = (e: any) => {
		const dataField = e.column.dataField
		if (!dynamicLookups[dataField]) {
			return
		}
		const data = JSON.stringify(e.data)
		axios
			.get(`${API_FIBER_URL}/view/lookup?folder=${folder}&model=${model}&field=${dataField}&data=${data}`)
			.then(response => response.data)
			.then(data => {
				setRowDynamicLookup(data.lookup)
			})
	}

	const onEditorPreparing = (e: any) => {
		const field = dynamicLocks['field' as any]
		const condition = dynamicLocks['condition' as any]
		const value = dynamicLocks['value' as any]

		if (e.dataField == field) {
			e.editorOptions.disabled = false
			return
		}

		switch (condition) {
			case '=':
				if (e.dataField && e.row?.data[field] == value) {
					e.editorOptions.disabled = true
				}
				break

			case '!=':
			case '<>':
				if (e.dataField && e.row?.data[field] !== value) {
					e.editorOptions.disabled = true
				}
				break
		}
	}

	const rightClickFilterOptions = (e: any) => {
		const dataField = e.column?.dataField

		let value = e.row?.data ? e.row?.data[dataField] : undefined
		if (value && e.column.dataType === 'date') {
			value = value.substring(0, 10)
		}
		let displayValue = value
		if (displayValue && displayValue.length > 30) {
			displayValue = `${displayValue.substr(0, 20)}...${displayValue.substr(-10)}`
		}

		if (e.target === 'content') {
			e.items = e.items || []
		}

		const addFilterItem = (text: any, selectedFilterOperation: any) => {
			const options = {
				filterValue: value,
				selectedFilterOperation,
			}
			e.items.push({
				text: `${text} "${displayValue}"`,
				icon: 'filter',
				onItemClick: () => {
					if (e.column.dataType === 'date') {
						options.filterValue = new Date(value)
					}
					gridRef.current?.instance.columnOption(dataField, options)
				},
			})
		}

		const addBulkEditItem = (dataField: string, bulkValue: any, bulkDisplayValue: string) => {
			e.items.push({
				text: `Bulk Edit: Column: "${dataField}" with value = "${bulkDisplayValue}".`,
				icon: 'tableproperties',
				onItemClick: async () => {
					const clickedAction = await confirmDialog(
						'Are you sure you want to perform bulk edit?',
						`You are going to edit ${totalCount} rows where column is "${dataField}" and the value will be: "${bulkDisplayValue}".`
					)
					if (clickedAction) {
						let url = `${API_FIBER_URL}/view/multiUpdate?`
						url = `${url}&model=${model}&folder=${folder}&field=${dataField}&value=${bulkValue}`
						if (dataFilter) {
							url = `${url}&filter=${dataFilter}`
						}
						try {
							await axios.put(url)
							notify(
								`Bulk edit successful: ${totalCount} rows updated with "${bulkDisplayValue}" in column "${dataField}"`,
								'success'
							)
							gridRef.current?.instance?.refresh()
						} catch (error) {
							notify(`Bulk edit failed`, 'error')
						}
					}
				},
			})
		}

		if (e.target === 'header' && mapColorFields && (mapColorFields as string[]).includes(dataField)) {
			e.items.push({
				text: 'Color by Column Values',
				icon: 'palette',
				onItemClick: async () => {
					try {
						const url = `${API_FIBER_URL}/view/distinct/?model=${model}&folder=${folder}&field=${dataField}`
						const response = await axios.get(url)
						Object.keys(response.data.distinctWithColors).forEach(key => {
							const newData = response.data.distinctWithColors[key]
							if (!valueColors.hasOwnProperty(key)) {
								setValueColors({ [key]: newData })
							}
						})
					} catch (error) {
						console.error('Error retrieving distinct values:', error)
					}
				},
			})
		}

		const canBulkUpdate =
			bulkUpdating &&
			totalCount &&
			totalCount <= 100 &&
			dataFilter &&
			locks &&
			!(locks as string[]).includes(dataField)

		if (
			e.target === 'content' &&
			(e.targetElement.className === 'dx-checkbox-icon' || e.targetElement.querySelector('.dx-checkbox-icon')) &&
			dataField &&
			canBulkUpdate
		) {
			addBulkEditItem(dataField, true, 'true')
			addBulkEditItem(dataField, false, 'false')
		} else if (e.target === 'content' && e.targetElement.tagName === 'TD' && dataField) {
			if (lookups.hasOwnProperty(dataField)) {
				const lookup = lookups[dataField]
				if (lookup[value]) {
					displayValue = lookup[value]
				}
				if (Array.isArray(lookup) && !(lookup as string[]).includes(value)) {
					value = displayValue = null
				}
			}

			const headerFilter = e.column.headerFilter?.dataSource
			if (value !== undefined) {
				if (headerFilter) {
					addFilterItem('Equal', '')
				} else if (value) {
					if (e.column.dataType === 'date') {
						addFilterItem('Equal', '=')
					} else {
						addFilterItem('Contains', null)
						addFilterItem('Does Not Contains', 'notcontains')
						addFilterItem('Equal', '=')
						addFilterItem('Does Not Equal', '<>')
					}
				}
				if (displayValue) {
					e.items.push({
						text: `Copy "${displayValue}"`,
						icon: 'copy',
						onItemClick: () => {
							const elem = document.createElement('textarea')
							elem.value = value
							document.body.appendChild(elem)
							elem.select()
							document.execCommand('copy')
							document.body.removeChild(elem)
							notify(`${displayValue} copied to clipboard`, 'info')
							setClipboard({ value, displayValue, dataType: e.column.dataType })
						},
					})
				}
				if (canBulkUpdate) {
					let bulkValue = value
					let bulkDisplayValue = displayValue
					if (clipboard && e.column.dataType === clipboard.dataType) {
						bulkValue = clipboard.value
						bulkDisplayValue = clipboard.displayValue

						if (bulkDisplayValue !== displayValue) {
							e.items.push({
								text: `Clear clipboard`,
								icon: 'clear',
								onItemClick: () => {
									notify(`The clipboard was cleared`, 'info')
									setClipboard(null)
								},
							})
						}
					}
					addBulkEditItem(dataField, bulkValue, bulkDisplayValue)
				}
			}

			e.items.push({
				text: `Audit Trail`,
				icon: 'floppy',
				onItemClick: () => {
					setAuditData(e.row.data)
					setAuditField(dataField)
					setShowAuditTrail(true)
				},
			})
		}
	}

	const handleDuplicate = () => {
		if (gridRef.current && gridRef.current.instance && selectedRow) {
			const newData = { ...selectedRow }
			// Remove the key field to create a new entry
			if (Array.isArray(key)) {
				key.forEach(k => delete newData[k])
			} else {
				delete newData[key]
			}

			// Use addRow instead of insert
			gridRef.current.instance.addRow()
			Object.keys(newData).forEach(field => {
				gridRef.current?.instance.cellValue(0, field, newData[field as keyof typeof newData])
			})
		}
	}

	const handleSaveData = async () => {
		const clickedAction = await confirmDialog('Multiple edit', `You are going to edit ${changes.length} rows.`)
		if (clickedAction) {
			let url = `${API_FIBER_URL}/view/massUpdate`
			const requestData = {
				model: model,
				folder: folder,
				changes: changes,
			}
			axios
				.patch(url, requestData)
				.then(response => {
					notify(`Data updated successfully`, 'success')
					gridRef.current?.instance?.refresh()
					setEditorMode(false)
				})
				.catch(error => {
					notify(`Multiple edit error!`, 'error')
				})
		}
	}

	const breadcrumb = (folders: any) => {
		let path = '' // Initialize an empty path
		return (
			folders &&
			folders.split('/').map((folder: string) => {
				path += `/${folder}` // Append current folder and a slash to the path
				return (
					<React.Fragment key={path}>
						<Link className="link" to={`/v${path}`}>
							{folder}
						</Link>{' '}
						/{' '}
					</React.Fragment>
				)
			})
		)
	}

	const headerDynamicLookups = (dataField: string) => {
		return dynamicLookups[dataField].map(item => {
			return {
				value: item.text,
				text: item.text,
			}
		})
	}

	const renderButtonPopContent = () => {
		return <LinkPermit args={buttonArgs} filter={dataFilter} totalCount={totalCount} />
	}

	const fileUploadFieldsAr = Object.keys(fileUploadFields)

	let changes: any[] = []

	return (
		<React.Fragment>
			<p className={'content-block'}>
				{totalCount !== null && (
					<span style={{ float: 'right' }}>
						{totalCount} record{totalCount === 1 ? '' : 's'}
					</span>
				)}
				{breadcrumb(folder)} {modelText}
			</p>

			{dataSource && (
				<DataGrid
					ref={gridRef}
					height={height}
					className={'dx-card wide-card'}
					dataSource={dataSource}
					dateSerializationFormat="yyyy-MM-dd"
					allowColumnReordering={true}
					remoteOperations={true}
					focusedRowEnabled={true}
					autoNavigateToFocusedRow={false}
					defaultFocusedRowIndex={0}
					hoverStateEnabled={true}
					columnAutoWidth={true}
					showBorders={false}
					allowColumnResizing={true}
					columnResizingMode="widget"
					onContextMenuPreparing={rightClickFilterOptions}
					onOptionChanged={(e: any) => {
						if (e.fullName === 'editing.changes') {
							const newData = e.value
							changes = newData
						}
						if (e.fullName.indexOf('groupIndex') > -1) {
							if (typeof e.value !== 'undefined') {
								setGrouping(grouping + 1)
							} else {
								setGrouping(grouping - 1)
							}
						} else {
							handleOptionChange('view', e, { folder, model }, null)
						}
					}}
					defaultFilterValue={filter ?? undefined}
					onExporting={(e: any) => {
						if (exportWithPhotos && totalCount && totalCount > 100) {
							notify('You need to filter max 100 rows', 'info')
							return
						}
						handleExporting('view', e, { folder, model })
					}}
					onFocusedRowChanged={onFocusedRowChanged}
					onRowPrepared={rowPrepared}
					onCellPrepared={cellPrepared}
					onEditingStart={onEditingStart}
					onEditorPreparing={onEditorPreparing}
					onInitNewRow={() => SetIsNewRow(true)}
					onEditCanceled={() => SetIsNewRow(false)}
					onRowInserted={() => SetIsNewRow(false)}
				>
					{editorMode && (
						<KeyboardNavigation
							enabled={true}
							editOnKeyPress={true}
							enterKeyAction={'startEdit'}
							enterKeyDirection={'column'}
						/>
					)}
					<Scrolling mode="virtual" useNative={true} />
					<Grouping autoExpandAll={false} />
					<GroupPanel visible={true} />
					<Editing
						startEditAction={editorMode ? 'click' : 'dblClick'}
						mode={editorMode ? 'batch' : 'cell'}
						allowAdding={filter ? false : inserting}
						allowUpdating={updating}
						confirmDelete={true}
						allowDeleting={editorMode ? false : deleting}
					/>
					<Paging defaultPageSize={100} />
					<HeaderFilter visible={true} />
					<FilterRow visible={true} applyFilter={'onClick'} />
					<Selection mode="single" />
					<ColumnChooser enabled />
					<FilterPanel visible={true} />
					{fields.map((field: any, ndx: number) => {
						const dataType = fixDataType(field.dataType, field.dataField)
						fieldsDataTypes[field.dataField] = dataType
						const allowFiltering = lookups[field.dataField] || dynamicLookups[field.dataField]
						const isFileUploadField = fileUploadFieldsAr.indexOf(field.dataField) > -1
						return (
							<Column
								key={field.dataField}
								dataField={field.dataField}
								dataType={dataType}
								defaultSortIndex={sort && field.dataField === sort.field ? 0 : undefined}
								defaultSortOrder={sort && field.dataField === sort.field ? sort.order : undefined}
								{...(dataType === 'boolean'
									? {
											calculateCellValue: (rowData: any) => {
												if (rowData[field.dataField] === true) {
													return true
												}
												return parseInt(rowData[field.dataField]) ? true : false
											},
									  }
									: multipleLookups.indexOf(field.dataField) > -1
									? {
											editCellComponent: MultipleSelect,
											cellTemplate: cellMultipleSelect,
									  }
									: isFileUploadField
									? {
											editCellComponent: UploadSection,
									  }
									: {})}
								format={
									field.dataType === 'custom_datetime'
										? 'dd/MM/yyyy HH:mm'
										: dataType === 'date'
										? 'dd/MM/yyyy'
										: ''
								}
								caption={field.dataField}
								visible={field.visible}
								showEditorAlways={editorMode || dataType === 'boolean'}
								allowEditing={allowEditing(field.dataField, field.allowEditing)}
								allowGrouping={field.allowGrouping ?? true}
								allowFiltering={allowFiltering ? false : true}
								allowHeaderFiltering={allowFiltering ? true : false}
								calculateFilterExpression={
									((filterValue: any, selectedFilterOperation: string | null, target: string) => {
										if (dataType !== 'date') {
											switch (selectedFilterOperation) {
												case '=':
													if (filterValue === ' ') {
														return [
															[field.dataField, '=', ''],
															'or',
															[field.dataField, '=', ' '],
															'or',
															[field.dataField, '=', null],
														]
													}
													if (dataType === 'boolean') {
														if (filterValue === false) {
															return [
																[field.dataField, '=', false],
																'or',
																[field.dataField, '=', null],
															]
														}
													}
													break
												case '<>':
													if (filterValue === ' ') {
														return [
															[field.dataField, '<>', ''],
															'and',
															[field.dataField, '<>', ' '],
															'and',
															[field.dataField, '<>', null],
														]
													}
													if (filterValue !== null) {
														return [
															[field.dataField, '<>', filterValue],
															'or',
															[field.dataField, '=', null],
														]
													}
													break
												case 'notcontains':
													return [
														[field.dataField, 'notcontains', filterValue],
														'or',
														[field.dataField, '=', null],
													]
											}
										}

										switch (selectedFilterOperation) {
											case 'contains':
											case 'notcontains':
												filterValue = filterValue.replace(/\[/g, '[[]')
												break
										}

										if (field.dataType === 'uniqueidentifier') {
											return [field.dataField, selectedFilterOperation, filterValue]
										}

										if (gridRef.current && gridRef.current.instance) {
											const column = gridRef.current.instance.columnOption(field.dataField)
											if (column && column.defaultCalculateFilterExpression) {
												return column.defaultCalculateFilterExpression(
													filterValue,
													selectedFilterOperation,
													target
												)
											}
										}

										return [field.dataField, selectedFilterOperation, filterValue]
									}) as any
								}
								width={sizes[ndx] ?? undefined}
								minWidth={50}
								cellRender={
									dynamicLookups[field.dataField]
										? ({ data }) => {
												return data[field.dataField]
										  }
										: urls.indexOf(field.dataField as never) > -1
										? ({ data }) => {
												return renderUrl(data[field.dataField], sharePointLogged)
										  }
										: percentagesFields.indexOf(field.dataField as never) > -1
										? ({ data }) => {
												const num = parseFloat(data[field.dataField])
												return num % 1 === 0 ? num.toString() + ' %' : num.toFixed(2) + ' %'
										  }
										: undefined
								}
							>
								{lookups[field.dataField] && (
									<HeaderFilter
										allowSelectAll={false}
										dataSource={filterStore(field.dataField, lookups[field.dataField])}
									/>
								)}
								{lookups[field.dataField] && (
									<Lookup
										dataSource={lookupStore(lookups[field.dataField])}
										valueExpr="value"
										displayExpr="text"
									/>
								)}
								{dynamicLookups[field.dataField] && (
									<HeaderFilter
										allowSelectAll={false}
										dataSource={headerDynamicLookups(field.dataField)}
									/>
								)}
								{dynamicLookups[field.dataField] && (
									<Lookup
										allowClearing={true}
										dataSource={rowDynamicLookup}
										valueExpr="value"
										displayExpr="text"
									/>
								)}
								{field.required && <RequiredRule />}
							</Column>
						)
					})}
					{key && (
						<Summary>
							<GroupItem
								column={Array.isArray(key) ? key[0] : key}
								summaryType="count"
								displayFormat="{0} items"
							/>
						</Summary>
					)}
					<Toolbar>
						<Item name="groupPanel" />
						{Object.entries(buttons).map(([buttonKey, button]: any) => (
							<Item key={buttonKey} location="after">
								<Button
									hint={button.text}
									icon={button.icon}
									disabled={
										editorMode ||
										(!button.doc && totalCount === 0) ||
										(button.doc && Array.isArray(selectedRow))
											? true
											: false
									}
									onClick={async () => {
										if (button.model) {
											if (gridRef.current && gridRef.current.instance) {
												handleClick(
													gridRef.current.instance.getSelectedRowsData(),
													button.model as string,
													button.filter
												)
											}
										} else if (button.popup) {
											setButtonPopupShow(true)
											setButtonArgs(button.args)
										} else if (button.email) {
											const clickedAction = await confirmDialog(
												'Perform email send?',
												`You are going to send email with up ${totalCount} recipients.`
											)
											if (clickedAction) {
												const { field, check, subject, template, from, mailer } = button.email
												let url = `${API_FIBER_URL}/view/email?folder=${folder}&model=${model}&field=${field}&check=${check}&subject=${subject}`
												if (from) {
													url = `${url}&from=${from}`
												}
												if (template) {
													url = `${url}&template=${template}`
												}
												if (mailer) {
													url = `${url}&mailer=${mailer}`
												}
												if (dataFilter) {
													url = `${url}&filter=${dataFilter}`
												}
												axios
													.get(url)
													.then(response => response.data)
													.then(data => {
														notify(
															`The email was sent successfully to ${data.emails} recipients`,
															'success'
														)
														gridRef.current?.instance?.refresh()
													})
													.catch(error => {
														notify(`Email failed to send!`, 'error')
													})
											}
										} else if (button.doc) {
											const { func } = button.doc
											let { filename } = button.doc
											const params: any = {
												folder,
												model,
												func,
												filename,
												row: selectedRow,
											}
											if (!Array.isArray(key)) {
												filename = filename.replace('.docx', `_${params.row[key]}.docx`)
											}
											if (button.icon.indexOf('pdf') > -1) {
												params.type = 'pdf'
												filename = filename.replace('.docx', '.pdf')
											}
											axios({
												method: 'post',
												url: `${API_FIBER_URL}/view/doc`,
												data: params,
												responseType: 'blob',
											}).then(response => {
												const { data, headers } = response
												const blob = new Blob([data], { type: headers['content-type'] })
												saveAs(blob, filename)
											})
										}
									}}
								/>
							</Item>
						))}
						<Item name="applyFilterButton" />
						<Item location="after">
							<Button
								icon="revert"
								hint="Clear Filter"
								onClick={() => {
									gridRef.current?.instance?.clearFilter()
								}}
							/>
						</Item>
						<Item location="after">
							<Button
								icon="refresh"
								hint="Refresh"
								disabled={editorMode ? true : false}
								onClick={() => {
									gridRef.current?.instance?.refresh()
								}}
							/>
						</Item>
						<Item location="after">
							<Button
								icon="edit"
								hint="Edit Mode"
								disabled={!updating}
								onClick={async () => {
									if (editorMode) {
										const clickedAction = await confirmDialog(
											'Confirm Exit from Edit Mode',
											`Exiting edit mode will discard changes made to ${changes.length} rows. Are you sure you want to proceed?`
										)
										if (clickedAction) {
											setEditorMode(editorMode => !editorMode)
										}
									} else {
										setEditorMode(editorMode => !editorMode)
									}
								}}
							/>
						</Item>
						<Item location="after">
							<Button
								icon="save"
								hint="Save"
								className={editorMode ? 'flashButton' : undefined}
								disabled={!updating || !editorMode}
								onClick={handleSaveData}
							/>
						</Item>
						<Item name="addRowButton" />
						{duplicating && (
							<Item location="after">
								<Button
									icon="copy"
									hint="Duplicate"
									disabled={!editorMode || filter || Array.isArray(selectedRow) ? true : false}
									onClick={handleDuplicate}
								/>
							</Item>
						)}
						<Item name="exportButton" disabled={editorMode ? true : false} />
						<Item name="columnChooserButton" />
						<Item location="after">
							<Button
								icon="detailslayout"
								hint="Row Detail"
								disabled={editorMode || Array.isArray(selectedRow) ? true : false}
								onClick={() => {
									setShowRow(true)
								}}
							/>
						</Item>
						{map.fields && (
							<Item location="after">
								<Button
									icon="map"
									hint="Map"
									onClick={() => {
										setShowMap(!showMap)
									}}
								/>
							</Item>
						)}
					</Toolbar>
					{detail.model && <MasterDetail enabled={true} component={DetailSection} />}
					<Export enabled={true} />
				</DataGrid>
			)}
			{map.fields && key && (
				<Map
					folder={folder}
					model={model}
					filter={dataFilter}
					title={map.title || null}
					settings={map.settings}
					visible={showMap}
					selected={!Array.isArray(key) ? selectedRow[key] : null}
					totalCount={totalCount}
					onClose={handleMapPopupClose}
					fields={fields}
					urls={urls}
					valueColors={valueColors}
				/>
			)}
			{showRow && (
				<Row
					fields={fields}
					urls={urls}
					values={selectedRow}
					lookups={lookups}
					onHide={() => setShowRow(false)}
				/>
			)}

			{showAuditTrail && (
				<AuditTrail
					visible={showAuditTrail}
					pKey={key}
					dataField={auditField}
					folder={folder}
					model={model}
					data={auditData}
					onHide={() => setShowAuditTrail(false)}
				/>
			)}

			<Popup
				visible={buttonPopupShow}
				showTitle={false}
				contentRender={renderButtonPopContent}
				position="center"
				onHiding={() => setButtonPopupShow(false)}
				hideOnOutsideClick={true}
			/>
		</React.Fragment>
	)
}
